<template>
  <!--  <div class="iss-main group">groupItem{{ groupId }}-->
  <!--  -->
  <!--  </div>-->

  <div class="iss-main-grid">
    <div class="iss-search-wrap">
      <div class="toAllGroup cursor-p marginBottom5" @click="toAllGroup">
        <component
          :is="icons['LeftOutlined']"
          class="marginBottom5"
        ></component>
        全部关系组
      </div>
      <div class="title">
        <div class="contentTitle">
          <div class="contentOne">{{ groupMessage.groupName }}</div>
          <div class="contentT">{{ groupMessage.description }}</div>
        </div>
        <div class="titleRight cursor-p titleRight-nav">
          <a-button
            type="primary"
            class="marginRight5"
            @click="handleAddCircleList"
            >新增联系人</a-button
          >
          <div class="operate">
            <a-popover placement="bottom">
              <template #content>
                <div
                  class="marginBottom5"
                  style="cursor: pointer"
                  @click="handleEditList('edit')"
                >
                  <component
                    :is="icons['EditOutlined']"
                    class="activeColor marginRight5"
                  ></component>
                  {{
                    groupMessage.parentId === '0'
                      ? '编辑关系组'
                      : '编辑子关系组'
                  }}
                </div>
                <div
                  class="marginBottom5"
                  style="cursor: pointer"
                  @click="handleDelete"
                >
                  <component
                    :is="icons['DeleteOutlined']"
                    class="activeColor marginRight5"
                  ></component>
                  {{
                    groupMessage.parentId === '0'
                      ? '删除关系组'
                      : '删除子关系组'
                  }}
                </div>

                <div
                  class="marginBottom5"
                  style="cursor: pointer"
                  v-if="groupMessage.parentId === '0'"
                  @click="handleEditList('add')"
                >
                  <component
                    :is="icons['PlusOutlined']"
                    class="activeColor marginRight5"
                  ></component>
                  添加子关系组
                </div>
              </template>
              <component :is="icons['EllipsisOutlined']"></component>
            </a-popover>
          </div>
        </div>
      </div>
      <div class="mt-40">
        <contact-group-grid
          ref="gridRef"
          allow-selection
          :showHeader="true"
          :columns="columns"
          :code="$route.path"
          :url="url"
          :items="items"
          :url-params="{ ...parmas, groupId: $route.query.id }"
          :btn-operation="btnOperation"
          :scroll="{ x: 1200, y: gridHeight }"
          @fnGrid="handleGrid"
        >
          <template #operation="{ record }">
            <operation :options="options" :record="record" />
          </template>
          <template v-slot:avatar="{ record }">
            <a-avatar
              v-if="record.avatar"
              :src="setAvatar(record.avatar)"
              @click="handleGoToContact(record)"
              class="headerUrl"
            />
            <div v-else @click="handleGoToContact(record)" class="headerText">
              <div v-if="record.name">
                {{ record.name[0] }}
              </div>
            </div>
          </template>
          <template v-slot:name="{ record }">
            <div class="cursor-p" @click="handleGoToContact(record)">
              {{ record.name }}
            </div>
          </template>
          <template #frequency="{ record }">
            <!-- <div v-for="item in frequenctList" :key="item.id">
              <div
                v-if="item.itemCode === record.frequency"
                class="frequenct-item"
              >
                <img
                  src="@/assets/images/box/woshou.png"
                  alt=""
                  class="frequencyImage"
                />
                {{ item.itemNameEn }}
              </div>
            </div> -->
            <div class="frequenct-item" @click="handleOpenPinci(record)">
              <img
                src="@/assets/images/box/woshou.png"
                alt=""
                class="frequencyImage"
              />
              {{ handlePinCi(record.frequency) }}
            </div>
          </template>
          <template #infoList="{ record }">
            <div class="recordContent">
              <div v-if="record.douYinUrl">
                <img
                  src="@/assets/images/box/dou.png"
                  alt=""
                  class="dou-img"
                  @click="handleLine(record.douYinUrl)"
                />
              </div>
              <div v-if="record.weiboUrl">
                <img
                  src="@/assets/images/box/wei.png"
                  alt=""
                  class="wei-img"
                  @click="handleLine(record.weiboUrl)"
                />
              </div>
              <div v-if="record.zhiHuUrl">
                <img
                  src="@/assets/images/box/zhi.png"
                  alt=""
                  class="zhi-img"
                  @click="handleLine(record.zhiHuUrl)"
                />
              </div>

              <div
                v-if="
                  record.infoList.every(
                    item => item.email === '' || item.email === null
                  )
                "
              >
                <div class="contact-icon" style="margin-right: 10px">
                  <img
                    src="@/assets/images/box/youhui.png"
                    alt=""
                    class="youhui-img"
                  />
                </div>
              </div>
              <a-dropdown placement="bottomCenter" v-else>
                <div class="contact-icon">
                  <img
                    src="@/assets/images/box/you.png"
                    alt=""
                    class="activeColor email"
                  />
                </div>
                <template #overlay>
                  <!--              cricleList  v-if="item.email === null || item.email === ''" -->
                  <a-menu
                    :class="{
                      dropdownCalss: record.infoList.some(
                        item => item.email === null || item.email === ''
                      ),
                    }"
                  >
                    <a-menu-item
                      v-for="item in record.infoList"
                      :key="item.id"
                      class="menuClass"
                      :class="{
                        nonePhone: item.email === '' || item.email === null,
                      }"
                    >
                      {{ item.email }}
                      <div
                        class="menuText activeColor"
                        v-if="item.email"
                        @click="getEmail(item)"
                      >
                        复制
                      </div>
                    </a-menu-item>

                    <div
                      v-if="
                        record.infoList.length === 0 ||
                        record.infoList.some(
                          item => item.email !== null || item.email === ''
                        )
                      "
                      @click="getAllEmail(record.infoList)"
                      class="allValue activeColor"
                    >
                      复制全部
                    </div>
                  </a-menu>
                </template>
              </a-dropdown>

              <div
                v-if="
                  record.infoList.every(
                    item => item.phone === '' || item.phone === null
                  )
                "
              >
                <div class="contact-icon" style="margin-right: 10px">
                  <img
                    src="@/assets/images/box/dianhui.png"
                    alt=""
                    class="dianhui-img"
                  />
                </div>
              </div>
              <!--          电话   -->
              <a-dropdown placement="bottomCenter" v-else>
                <!--                <component :is="icons['PhoneOutlined']" class="activeColor"/>-->
                <div class="contact-icon">
                  <img
                    src="@/assets/images/box/dian.png"
                    alt=""
                    class="activeColor cursor-p"
                  />
                </div>

                <template #overlay>
                  <!--              cricleList   -->
                  <a-menu
                    @click="handleClickByMenu"
                    :class="{
                      dropdownCalss: record.infoList.some(
                        item => item.phone === null || item.phone === ''
                      ),
                    }"
                  >
                    <a-menu-item
                      v-for="item in record.infoList"
                      :key="item.id"
                      class="menuClass"
                      :class="{
                        nonePhone: item.phone === '' || item.phone === null,
                      }"
                    >
                      {{ item.phone }}
                      <div
                        class="menuText activeColor"
                        v-if="item.phone"
                        @click="getValue(item)"
                      >
                        复制
                      </div>
                    </a-menu-item>
                    <div
                      @click="getAllValue(record.infoList)"
                      class="allValue activeColor"
                      v-if="
                        record.infoList.length === 0 ||
                        record.infoList.some(
                          item => item.phone !== null || item.phone === ''
                        )
                      "
                    >
                      复制全部
                    </div>
                  </a-menu>
                </template>
              </a-dropdown>
            </div>
          </template>
          <template v-slot:lastInteractionTime="{ record }">
            <div>
              {{ handleTime(record) }}
            </div>
          </template>
        </contact-group-grid>
      </div>
    </div>
  </div>

  <iss-add-page
    v-model:visible="visibleAddPage"
    :activeItem="activeItem"
    @fnOk="handleAddPage"
  />

  <iss-frequency
    v-model:visible="visibleFrequency"
    :init="activeItemFrequency"
    @fnOk="handleFrequencyPage"
  />
  <iss-add-group
    v-model:visible="visibleAddGroup"
    :init="activeItemAddGroup"
    @fnOk="handleAddGroup"
  />
  <iss-pulse
    v-model:visible="visiblePluse"
    :init="activeItemPluse"
    @fnOk="handlePluse"
  />

  <iss-edit
    v-model:visible="visibleEdit"
    :init="activeItemEdit"
    @fnOk="handleEditPage"
  />
  <!-- 新增联系人弹窗  -->
  <add-page
    v-model:visible="visibleContactsPage"
    :init="activeItemAdd"
    @fnOk="handleContactsPage"
  />
  <!--  编辑,添加关系组 -->
  <add-edit-group
    v-model:visible="visibleGroup"
    :init="activeItemGroup"
    @fnOk="handleGroup"
  />
</template>

<script>
import { useRoute } from 'vue-router';
import contactGroupGrid from '@/components/contactGroupGrid';
import Operation from '@/components/operation';
import { reactive, toRefs, ref, watch, createVNode } from 'vue';
import * as icons from '@ant-design/icons-vue';
// import contactAll from '@/api/contactAll';
// import IssFrequency from '@/views/contacts/contactAall/frequency';
import IssFrequency from '@/views/today/homePage/components/frequencyTwo';
import IssAddGroup from '@/views/contacts/contactAall/addGroup';
import IssEdit from '@/views/contacts/contactAall/edit';
import contactsPage from '@/views/contacts/contactAall/detailsPage/contactsPage';
import groupApi from '@/api/group';
import addPage from '@/views/group/addPage';
import addEditGroup from '@/views/group/addEditGroup';
import {
  message,
  Popover,
  Dropdown,
  Menu,
  Modal,
  Avatar,
  // Tooltip,
} from 'ant-design-vue';
import { useRouter } from 'vue-router';
// isDelBoundary
import { copyText, isDelBoundary } from '@/utils';
import { useStore } from 'vuex';
import Add from '@/views/group/addPage';
import { setAvatar } from '@/utils';
import menuApi from '@/api/menu';
import contactAll from '@/api/contactAll';
import IssPulse from '@/components/pulse';
// import router from "@/router";

export default {
  name: 'group',
  components: {
    Add,
    contactGroupGrid,
    Operation,
    Icon: icons,
    APopover: Popover,
    IssFrequency,
    IssAddGroup,
    IssEdit,
    contactsPage,
    ADropdown: Dropdown,
    AMenu: Menu,
    IssPulse,
    addPage,
    addEditGroup,
    Modal,
    AAvatar: Avatar,
    // ATooltip: Tooltip,
  },
  setup() {
    const route = useRoute();
    const { id } = route.query;
    const store = useStore();
    const userId = store.state.account.user.id;
    const gridRef = ref();
    const router = useRouter();
    const state = reactive({
      parentId: '',
      visibleFrequency: false,
      activeItemFrequency: [],
      visibleAddGroup: false,
      activeItemAddGroup: [],
      visibleEdit: false,
      activeItemEdit: [],
      visibleContactsPage: false,
      activeItemAdd: {},
      visibleAddPage: false,
      visiblePluse: false,
      activeItemPluse: [],
      visibleGroup: false,
      frequenctList: [],
      activeItemGroup: {},
      // isDrag: false,
      visible: false,
      initValue: {},
      groupMessage: {},
      parmas: {
        groupId: id,
        userId: userId,
        conditionDto: {
          sortMap: {
            field: '',
            sort: 'asc',
          },
          fieldList: [
            'company',
            'position',
            'describe',
            'frequency',
            'last_interaction_time',
            'socialize_url',
          ],
          conditionMap: [
            {
              field: '',
              relation: '',
              value: '',
            },
          ],
        },
      },
      name: '',
    });

    const getGroup = id => {
      groupApi.groupsId('', id).then(res => {
        if (res) {
          state.groupMessage = res;
        }
      });
    };

    groupApi.groupsId('', id).then(res => {
      if (res) {
        state.groupMessage = res;
      }
    });

    contactAll.getTree('', { code: 'FREQUENCY' }).then(res => {
      if (res) {
        Object.assign(state.frequenctList, res);
      }
    });

    watch(
      () => route.query,
      parmas => {
        if (parmas.id) {
          state.parmas.groupId = parmas.id;
          // 根据路由变化去情况筛选值
          if (gridRef.value) {
            gridRef.value.clearSearch();
          }
          getGroup(parmas.id);
        }
      },
      { deep: true }
    );

    const handleEditList = type => {
      // console.log('type', type);
      state.visibleGroup = true;
      state.activeItemGroup = {
        id: id,
        type: type,
        groupMessage: state.groupMessage,
        show: true,
      };
    };

    const getList = () => {
      menuApi
        .getList({
          userId: store.state.account.user.id,
        })
        .then(res => {
          if (res) {
            res = res.map(i => {
              const obj = {
                id: i.id,
                parentId: i.parentId,
                path: '/group/groupItem?id=' + i.id,
                name: 'groupItem',
                menuKey: 'groupItem',
                type: 'groupItem',
                component: 'group/groupItem',
                accessWay: 'builtin',
                description: i.description,
                meta: {
                  title: i.groupName,
                  icon: i.icon,
                  breadcrumb: true,
                  size: i.size,
                },
                hidden: false,
                alwaysShow: false,
              };
              if (i.children?.length) {
                i.children = i.children.map(item => {
                  return {
                    id: item.id,
                    parentId: item.parentId,
                    path: '/group/groupItem?id=' + item.id,
                    name: 'groupItem',
                    menuKey: 'groupItem',
                    type: 'groupItem',
                    component: 'group/groupItem',
                    accessWay: 'builtin',
                    description: item.description,
                    meta: {
                      title: item.groupName,
                      icon: item.icon,
                      breadcrumb: true,
                      size: item.size,
                    },
                    hidden: false,
                    alwaysShow: false,
                  };
                });
                return {
                  ...obj,
                  children: i.children,
                };
              } else {
                return obj;
              }
            });
            let target = [
              {
                path: '/groupMenu',
                // path: '',
                name: 'groupMenu',
                menuKey: 'groupMenu',
                component: 'Layout',
                accessWay: 'builtin',
                description: '关系组关系组',
                meta: {
                  title: '关系组',
                  icon: 'custom',
                  breadcrumb: true,
                  select: '@/assets/images/menu/groupS.png',
                  unSelect: '@/assets/images/menu/group.png',
                },
                hidden: false,
                alwaysShow: false,
                children: [],
              },
            ];
            target[0].children = res;
            store.commit('account/setCustomMenus', target);
          }
        });
    };

    return {
      ...toRefs(state),
      icons,
      setAvatar,
      gridRef,
      id,
      handleEditList,
      // getMessage,
      handleGoToContact(record) {
        router.push(`/contacts/${record.id}`);
      },

      items: [{ key: 'name', label: '搜索姓名、公司、职位', type: 'input' }],
      columns: [
        {
          dataIndex: 'avatar',
          title: '头像',
          width: 60,
          ellipsis: true,
          slots: { customRender: 'avatar' },
        },
        {
          dataIndex: 'name',
          title: '姓名',
          width: 160,
          ellipsis: true,
          slots: { customRender: 'name' },
        },
        {
          dataIndex: 'company',
          title: '公司',
          width: 120,
          ellipsis: true,
          slots: { customRender: 'company' },
        },
        { dataIndex: 'position',  title: '职位', width: 120, ellipsis: true },
        { dataIndex: 'describe', title: '备注', width: 120, ellipsis: true },

        // {
        //   dataIndex: 'last_interaction_time',
        //   title: '最后互动时间',
        //   width: 120,
        //   ellipsis: true,
        //   slots: { customRender: 'last_interaction_time' },
        // },
        {
          dataIndex: 'frequency',
          title: '联系频次',
          width: 120,
          ellipsis: true,
          slots: { customRender: 'frequency' },
        },
        //   号码
        {
          dataIndex: 'infoList ',
          title: '联系方式',
          width: 160,
          ellipsis: true,
          slots: { customRender: 'infoList' },
        },
        //   最后互动时间 last_interaction_time
        {
          dataIndex: 'lastInteractionTime',
          title: '最后互动时间',
          width: 120,
          ellipsis: true,
          slots: { customRender: 'lastInteractionTime' },
        },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 60,
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        {
          type: 'selectAll',
          label: '跨页全选',
          permission: 'resources:selectAll',
          fnClick: () => {
            // console.log('全选', gridRef.value.getSelectedRowsKeys());
          },
        },
        {
          type: 'sort',
          label: '排序',
          permission: 'resources:sort',
          slots: { customRender: 'sort' },
          fnClick: () => {
            console.log('排序排序', gridRef.value.getSelectedRowsKeys());
          },
        },
        {
          type: 'field',
          label: '字段',
          permission: 'resources:field',
          fnClick: () => {
            console.log('字段');
          },
        },
        {
          type: 'sift',
          label: '筛选',
          permission: 'resources:sift',
          fnClick: () => {
            console.log('筛选');
          },
        },
        //    有勾选数据情况下则展示下面这个几个操作按钮,不包含扩展符
        {
          type: 'contactFrequency',
          label: '联系频次',
          permission: 'resources:contactFrequency',
          fnClick: () => {
            // state.activeItemFrequency = gridRef.value.getSelectedRowsKeys();
            let parmas = {
              ...gridRef.value.getSelectedRowsKeysAll(),
              pageDTO: {
                conditionDto: state.parmas.conditionDto,
                name: state.name
              }
            }
            state.activeItemFrequency = parmas
            state.visibleFrequency = true;

          },
        },
        {
          type: 'addGroup',
          label: '添加到关系组',
          permission: 'resources:addGroup',
          fnClick: () => {
            let parmas = {
              ...gridRef.value.getSelectedRowsKeysAll(),
              pageDTO: {
                conditionDto: state.parmas.conditionDto,
                name: state.name
              }
            }
            state.activeItemAddGroup = parmas
            state.visibleAddGroup = true;
            // state.activeItemAddGroup = gridRef.value.getSelectedRowsKeys();
          },
        },
        {
          type: 'addPulse',
          label: '添加人脉圈',
          permission: 'resources:addPulse',
          fnClick: () => {
            state.visiblePluse = true;
            let parmas = {
              ...gridRef.value.getSelectedRowsKeysAll(),
              pageDTO: {
                conditionDto: state.parmas.conditionDto,
                name: state.name
              }
            }
            state.activeItemPluse = parmas
          },
        },
        {
          type: 'delGroup',
          label: '移出关系组',
          permission: 'resources:addPulse',
          fnClick: () => {
            // console.log('点击移除关系组');
            // state.activeItemPluse = gridRef.value.getSelectedRowsKeys();
            Modal.confirm({
              title: `确认把联系人移出关系组吗？`,
              icon: createVNode(icons['ExclamationCircleOutlined']),
              okType: '',
              okText: '确认',
              onOk: () => {
                let data = {
                  userId: store.state.account.user.id,
                  contactId: gridRef.value.getSelectedRowsKeysAll().id,
                  groupId: route.query.id,
                  selectAll: gridRef.value.getSelectedRowsKeysAll().selectAll,
                  pageDTO: {
                    conditionDto: state.parmas.conditionDto,
                    name: state.name
                  }

                };
                groupApi.deleteGroupUser('', data).then(res => {
                  if (res) {
                    message.success('移出成功');
                    gridRef.value.refreshGrid(gridRef.value.getSelectedRowsKeysAll().id.length);
                    // 刷新关系组菜单
                    getList();
                  }
                });
              },
            });
          },
        },
        {
          type: 'edit',
          label: '编辑',
          permission: 'resources:edit',
          fnClick: () => {
            state.visibleEdit = true;
            let parmas = {
              ...gridRef.value.getSelectedRowsKeysAll(),
              pageDTO: {
                conditionDto: state.parmas.conditionDto,
                name: state.name
              }
            }
            state.activeItemEdit = parmas
          },
        },
        {
          type: 'extend',
          label: '...',
          permission: 'resources:extend',
          fnClick: () => {
            console.log('扩展符');
          },
        },
      ],
      options: [
        {
          type: 'detail',
          label: '详情',
          icon: 'EyeTwoTone',
          permission: 'distribution:detail',
          fnClick: record => {
            router.push(`/contacts/${record.id}`);
          },
        },
      ],
      // 关系组分页
      url: groupApi.pageChildUrlItem,
      gridHeight: document.body.clientHeight - 427,

      handleGrid: parmas => {
        // grid传来的值
        let { value, type } = parmas;
        // console.log('grid传来的值', value, type);
        if (type === 'sort') {
          if (value === 'last_interaction_time' || value === 'create_time') {
            state.parmas.conditionDto.sortMap.sort = 'desc';
            gridRef.value.refreshGrid();
          } else {
            state.parmas.conditionDto.sortMap.sort = 'asc';
            gridRef.value.refreshGrid();
          }
          state.parmas.conditionDto.sortMap.field = value;
          gridRef.value.refreshGrid();
        } else if (type === 'field') {
          state.parmas.conditionDto.fieldList = value;
          gridRef.value.refreshGrid();
        } else if (type === 'sift') {
          // console.log('valuefgfd', value);
          if (value) {
            state.parmas.conditionDto.conditionMap = value;
            gridRef.value.refreshGrid();
          } else {
            state.parmas.conditionDto.conditionMap = [];
            gridRef.value.refreshGrid();
          }
        } else if (type === 'extend') {
          console.log('values删除', value)
          // 处理删除分页器的边界
          // selectAll 是否跨页全选， cancelRowsIdLength 取消勾选Id 数组长度, total 总数， id 勾选id 数组长度
          let delStu = isDelBoundary(value.selectAll, gridRef.value.getSelectedRowsKeysAll().id.length, gridRef.value.isTotal(), value.contactId.length)
          if (!delStu) {
            return message.error('请选择联系人');
          }

          // if (value.contactId.length === 0) {
          //   return message.error('请先选择联系人');
          // } else {
          //   console.log('contactId', gridRef.value.getSelectedRowsKeysAll().id)

            Modal.confirm({
              title: `确认删除选中的的联系人吗？`,
              icon: createVNode(icons['ExclamationCircleOutlined']),
              okType: '',
              okText: '确认',
              onOk: () => {

                let data = {
                  userId: store.state.account.user.id,
                  contactId: gridRef.value.getSelectedRowsKeysAll().id,
                  groupId: route.query.id,
                  selectAll: gridRef.value.getSelectedRowsKeysAll().selectAll,
                  pageDTO: {
                    conditionDto: state.parmas.conditionDto,
                    name: state.name
                  }

                };
                contactAll.deleteContactIds('', data).then(res => {
                  if (res) {
                    message.success('删除成功');
                    // 刷新关系组菜单
                    getList();
                    gridRef.value.refreshGrid(gridRef.value.getSelectedRowsKeysAll().id.length);
                  }
                }).catch(() => {
                  // message.error('网络异常，请联系技术人员处理')
                });
              },
            });

          // }
        }

        // gridRef.value.refreshGrid();
      },
      getSearch: parmas => {
        console.log('parmas筛选', parmas )
        state.name = parmas.name

      },
      handleLine(link) {
        window.open(link, '_blank');
      },
      getEmail(item) {
        copyText(item.email);
        message.success('复制成功！');
      },
      getAllEmail(record) {
        // 过滤包含非空email字段的对象
        const emails = record
          .filter(
            item =>
              item.email !== null &&
              item.email !== undefined &&
              item.email !== ''
          )
          .map(item => item.email);
        const result = emails.length > 0 ? emails.join(',') : '';
        copyText(result);
        message.success('复制成功！');
      },

      getValue(item) {
        copyText(item.phone);
        message.success('复制成功！');
      },
      getAllValue(record) {
        const emails = record
          .filter(
            item =>
              item.phone !== null &&
              item.phone !== undefined &&
              item.phone !== ''
          )
          .map(item => item.phone);
        const result = emails.length > 0 ? emails.join(',') : '';
        copyText(result);
        message.success('复制成功！');
      },

      handleAdd: () => {
        state.visibleAddPage = true;
      },
      handleAddPage: value => {
        state.visibleAddPage = value;
        gridRef.value.refreshGrid();
      },
      handlePluse(value) {
        state.visiblePluse = value;
        gridRef.value.refreshGrid();
      },

      handleAddCircleList() {
        state.visibleContactsPage = true;
        state.activeItemAdd = route.query.id;
      },

      handleContactsPage(value) {
        state.visibleContactsPage = value;
        // 刷新关系组菜单
        getList();
        gridRef.value.refreshGrid();
      },
      handleFnSearch: value => {
        const temp = Object.assign({}, value);
        // temp.tag = temp.tag?.join(',');
        state.search = temp;
      },
      handleAddCircle(value) {
        state.visible = value;
        gridRef.value.refreshGrid();
      },
      handleFrequencyPage(value) {
        state.visibleFrequency = value;
        gridRef.value.refreshGrid();
      },
      handleAddGroup(value) {
        state.visibleAddGroup = value;
        gridRef.value.refreshGrid();
        getList();
      },
      handleEditPage(value) {
        state.visibleEdit = value;
        gridRef.value.refreshGrid();
      },
      toAllGroup() {
        router.push('/group');
      },
      handleGroup() {
        state.visibleGroup = false;
        gridRef.value.refreshGrid();
        //   刷新
        getGroup(route.query.id);

        // 刷新关系组菜单
        getList();
      },

      handleDelete() {
        Modal.confirm({
          content: '您删除关系组后，该关系组下的联系人或子关系组将被一起删除！',
          icon: createVNode(icons['ExclamationCircleOutlined']),
          okText: '确认',
          onOk() {
            //删除
            menuApi
              .delete({
                userId: store.state.account.user.id,
                id: route.query.id,
              })
              .then(res => {
                if (res) {
                  message.success('删除成功');
                  // 刷新关系组菜单
                  getList();
                  //  跳转到全部群组
                  router.push('/group');
                }
              })
              .catch(() => {
                message.error('删除失败');
              });
          },
          cancelText: '取消',
          onCancel() {
            Modal.destroyAll();
          },
        });
      },
      handleTime(record) {
        if (record.lastInteractionTime) {
          let [datePart] = record.lastInteractionTime?.split(' ');
          return datePart?.replace(/-/g, '/');
        } else {
          return '';
        }
      },
      handlePinCi(frequency) {
        if (frequency === '80') {
          return '无';
        }
        if (frequency === '10') {
          return '每周';
        }
        if (frequency === '20') {
          return '每月';
        }
        if (frequency === '30') {
          return '每季度';
        }
        if (frequency === '40') {
          return '每半年';
        }
        if (frequency === '50') {
          return '每年';
        }
        if (frequency === '60') {
          return '不要保持联系';
        }
      },
      handleOpenPinci(item) {
        console.log('handleOpenPinci', item);
        state.visibleFrequency = true;
        state.activeItemFrequency = [item.id];
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-main-grid {
  height: calc(100vh - 88px);
  //max-height: calc(100vh - 88px);
  overflow-y: auto;
  background: #fff;
  padding: 32px 54px;
}
.iss-main-grid /deep/ .ant-table-placeholder {
  border-bottom: none;
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // .contentTitle {
  // }
  .titleRight {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 30%;

    .titleRight-nav {
      display: flex;
      justify-content: right;
      :deep(.ant-btn) {
        background: #ff7b00;
        border: 1px solid #ff7b00;
        color: #fff;
      }

      .operate {
        margin-left: 15px;
        width: 32px;
        height: 32px;
        line-height: 32px;
        border-radius: 4px;
        text-align: center;
        opacity: 1;
        background: #ffffff;
        box-sizing: border-box;
        border: 1px solid #eeeff5;
        color: #333;
        font-size: 20px;
      }
    }

    .operate {
      border: 1px solid #eeeff5;
      background: #ffffff;
      border-radius: 4px;
      padding: 3px 6px;
    }
  }
}
.contentOne {
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  color: #333333;
}
.contentT {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  color: #666666;
  margin-top: 10px;
}
.contentTwo {
  font-size: 12px;
  margin-top: 8px;
}
.contentAdd {
  font-size: 14px;
  background: #ff7b00;
  padding: 9px 22px;
  color: #fff;
  border-radius: 4px;
  opacity: 1;
  cursor: pointer;
}
.headerUrl {
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
//.headerText {
//  cursor: pointer;
//  width: 24px;
//  height: 24px;
//  border-radius: 50%;
//  background: #FF8F1F;
//  color: #FFFFFF;
//}
.toAllGroup {
  margin-bottom: 10px;
}
.recordContent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.wei-img,
.zhi-img,
.dou-img {
  margin-right: 12px;
  cursor: pointer;
}
.dropdownCalss {
  min-width: 203px;
  min-height: 78px;
}

.email {
  margin-right: 12px;
  cursor: pointer;
}
.menuClass {
  display: flex;
  justify-content: space-between;
  margin: 12px;

  .menuText {
    margin-left: 24px;
    cursor: pointer;
  }
}
.allValue {
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  border-top: 0.5px solid #eeeeee;
}
.nonePhone {
  display: none;
}
.frequencyImage {
  width: 18px;
  height: 18px;
}

.frequenct-item {
  width: auto;
  padding: 4px 10px;
  box-sizing: border-box;
  display: inline-block;
  border: 1px solid #eeeff5;
  border-radius: 4px;
  background: #fff;

  span {
    color: #6b7592;
    font-size: 12px;
    font-weight: 400;
  }
}

.contact-icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 50%;
}
</style>
