<template>
  <div class="flex-top" @click.stop>
    <div class="flex-l">
      <!--      {{ tableTitle }} checkAll-->
      <template v-for="item in btnOperation">
        <template v-if="item.type === 'selectAll'">
          <div :key="item.type" @click="handleClick(item)" class="buttonAll">
            <a-checkbox
              v-model:checked="checkAll"
              class="checkClass"
              @change="handleChangeByCheckAll"
            ></a-checkbox>
            {{ item.label || '跨页全选' }}
          </div>
        </template>
      </template>
    </div>
    <div class="flex-r">
      <a-space :size="12" align="baseline">
        <template v-for="item in btnOperation">
          <template v-if="item.upload">
            <a-upload
              :key="item.type"
              :showUploadList="false"
              :headers="{
                tenant: $store.state.account.tenant,
                token: $store.state.account.token,
                code: item.permission,
              }"
              v-bind="item.upload"
              @change="item.fnChange"
            >
              <a-button>
                <template #icon>
                  <component :is="icons[item.icon || 'UploadOutlined']" />
                </template>
                {{ item.label || '上传' }}
              </a-button>
            </a-upload>
          </template>
          <template v-else-if="item.type === 'add'">
            <a-button
              :key="item.type"
              type="primary"
              @click="handleClick(item)"
            >
              <template #icon>
                <component :is="icons[item.icon || 'PlusOutlined']" />
              </template>
              {{ item.label || '新增' }}
            </a-button>
          </template>
          <template v-else-if="item.type === 'delete'">
            <a-button :key="item.type" @click="handleClick(item)">
              <template #icon>
                <component :is="icons[item.icon || 'DeleteOutlined']" />
              </template>
              {{ item.label || '批量删除' }}
            </a-button>
          </template>
          <template v-else-if="item.type === 'file'">
            <a-button
              :key="item.type"
              :type="item.btnType"
              @click="handleClickFile(item)"
            >
              <template #icon>
                <component :is="icons[item['icon']]" />
              </template>
              {{ item.label }}
            </a-button>
            <a-modal
              :title="item.label"
              :key="item.type"
              :visible="visible"
              centered
              @cancel="visible = false"
              @ok="handleClickOk(item)"
            >
              <template v-for="child in item.children">
                <template v-if="child.upload">
                  <a-row
                    :key="child.type"
                    type="flex"
                    align="center"
                    :class="{ 'mb-32': !child.extra }"
                  >
                    <a-col :span="4" class="ta-right"
                      >{{ child.label }}：</a-col
                    >
                    <a-col :span="20">
                      <a-upload
                        :key="child.type"
                        :showUploadList="false"
                        :headers="{
                          tenant: $store.state.account.tenant,
                          token: $store.state.account.token,
                          code: child.permission,
                        }"
                        v-bind="child.upload"
                        @change="child.fnChange"
                      >
                        <a-button>
                          <template #icon>
                            <component
                              :is="icons[child.icon || 'UploadOutlined']"
                            />
                          </template>
                          {{ child.label || '上传' }}
                        </a-button>
                      </a-upload>
                    </a-col>
                  </a-row>
                  <a-row :key="child.type" v-if="child.extra">
                    <a-col :span="4"></a-col>
                    <a-col :span="20" class="iss-extra">
                      <span>{{ child.extra }}</span>
                    </a-col>
                  </a-row>
                </template>
                <template v-else>
                  <a-row
                    :key="child.type"
                    type="flex"
                    align="middle"
                    :class="{ 'mb-32': !child.extra }"
                  >
                    <a-col :span="4" class="ta-right"
                      >{{ child.label }}：</a-col
                    >
                    <a-col :span="20">
                      <a-button
                        :type="child.btnType"
                        @click="handleClick(child)"
                      >
                        <template #icon>
                          <component :is="icons[child['icon']]" />
                        </template>
                        {{ child.label }}
                      </a-button>
                    </a-col>
                  </a-row>
                  <a-row :key="child.type" v-if="child.extra">
                    <a-col :span="4"></a-col>
                    <a-col :span="20" class="iss-extra">
                      <span>{{ child.extra }}</span>
                    </a-col>
                  </a-row>
                </template>
              </template>
            </a-modal>
          </template>
          <!--      排序   -->
          <template v-else-if="item.type === 'sort'">
            <a-dropdown :key="item.type">
              <a-button
                :key="item.type"
                @click="handleClick(item)"
                class="butStyle"
                v-show="checkAll ? !checkAll : rowSelection.selectedRowKeys.length === 0 ? true : false"
              >
                <img
                  src="@/assets/images/box/paixu.png"
                  alt=""
                  class="gridHeader-img"
                />
                {{ item.label }}
              </a-button>
              <template #overlay>
                <a-menu @click="handleClickByMenu">
                  <a-menu-item v-for="i in list" :key="i.value">
                    <div v-if="i.label === '名字'">
                      {{ i.label }}{{ 'A'
                      }}<component :is="icons['SwapOutlined']" />{{ 'Z' }}
                    </div>
                    <div v-else>
                      {{ i.label }}
                    </div>
                  </a-menu-item>
                </a-menu>

                <!-- <a-menu @click="handleClickByMenu" v-else>-->
                <!--   <a-menu-item v-for="i in cricleList" :key="i.value">-->
                <!--     {{ i.label }}-->
                <!--   </a-menu-item>-->
                <!-- </a-menu>-->
              </template>
            </a-dropdown>
          </template>
          <!--        字段   -->
          <template v-else-if="item.type === 'field'">
            <a-button
              :key="item.type"
              @click="handleClick(item)"
              class="butStyle"
              v-show="checkAll ? !checkAll : rowSelection.selectedRowKeys.length === 0 ? true : false"
            >
              <img
                src="@/assets/images/box/gridHeader.png"
                alt=""
                class="gridHeader-img"
              />
              {{ item.label }}
            </a-button>

            <div :key="item.type" class="fieldEle" v-show="isShow">
              <div class="fieldTitle">列表字段:</div>
              <div :key="item.type" class="fieldValue">
                <div
                  v-for="i in fieldList"
                  :key="i.id"
                  class="fieldItem"
                  @click="handleFieldItem(i)"
                  :class="{ activeFieldItem: i.setStatus }"
                >
                  {{ i.label }}
                </div>
              </div>
            </div>
          </template>
          <!--           筛选  -->
          <template v-else-if="item.type === 'sift'">
            <a-button
              :key="item.type"
              @click="handleClick(item)"
              id="step1"
              class="butStyle"
              v-show="checkAll ? !checkAll : rowSelection.selectedRowKeys.length === 0 ? true : false"
            >
              <img src="@/assets/images/box/sift.png" alt="" class="sift-img" />
              {{ item.label }}
            </a-button>

            <!--      条件筛选     -->
            <div :key="item.type" class="siftCalss" v-show="isShowSift">
              <iss-sift @fnSift="handleSiftPage" />
            </div>
          </template>
          <template v-else-if="item.type === 'extend'">
            <a-button
              :key="item.type"
              @click="handleClick(item)"
              class="butStyle"
            >
              <img
                src="@/assets/images/box/export.png"
                alt=""
                class="export-img"
              />
              <!-- {{ '' }} -->
            </a-button>
            <!--         展示删除    -->
            <div :key="item.type" class="extend" v-show="showExtend">
              <div :key="item.type" class="fieldValue">
                <!-- <div class="exportClass" @click="handleExport">-->
                <!--   <img-->
                <!--     src="@/assets/images/box/exportImg.png"-->
                <!--     alt=""-->
                <!--     class="export-img"-->
                <!--   />-->
                <!--  导出 -->
                <!-- </div>-->
                <div class="deleteClass" @click="handleDelete">
                  <img
                    src="@/assets/images/box/del.png"
                    alt=""
                    class="delete-img"
                  />
                  删除
                </div>
              </div>
            </div>
          </template>
          <!-- 有勾选数据情况下则展示下面这个几个操作按钮,不包含扩展符 extend -->
          <template
            v-else-if="
              item.type === 'contactFrequency' && (rowSelection.selectedRowKeys.length > 0 || checkAll === true)
            "
          >
            <a-button
              :key="item.type"
              @click="handleClick(item)"
              id="step2"
              class="butStyle"
            >
              <img
                src="@/assets/images/box/lashou.png"
                alt=""
                class="contact-img"
              />
              {{ item.label }}
            </a-button>
          </template>
          <template
            v-else-if="
              item.type === 'addGroup' && (rowSelection.selectedRowKeys.length || checkAll === true)
            "
          >
            <a-button
              :key="item.type"
              @click="handleClick(item)"
              id="step3"
              class="butStyle"
            >
              <img src="@/assets/images/box/jia.png" alt="" class="group-img" />
              {{ item.label }}
            </a-button>
          </template>
          <template
            v-else-if="
              item.type === 'addPulse' && (rowSelection.selectedRowKeys.length || checkAll === true)
            "
          >
            <a-button
              :key="item.type"
              @click="handleClick(item)"
              id="step4"
              class="butStyle"
            >
              <img src="@/assets/images/box/jia.png" alt="" class="group-img" />
              {{ item.label }}
            </a-button>
          </template>
          <template
            v-else-if="
              item.type === 'edit' && (rowSelection.selectedRowKeys.length || checkAll === true)
            "
          >
            <a-button
              :key="item.type"
              @click="handleClick(item)"
              id="step5"
              class="butStyle"
            >
              <img src="@/assets/images/box/bi.png" alt="" class="edit-img" />
              {{ item.label }}
            </a-button>
          </template>
          <template
            v-else-if="
              item.type === 'delGroup' && (rowSelection.selectedRowKeys.length || checkAll === true)
            "
          >
            <a-button
              :key="item.type"
              @click="handleClick(item)"
              id="step5"
              class="butStyle"
            >
              <template #icon>
                <component :is="icons[item.icon || 'UserDeleteOutlined']" />
              </template>
              {{ item.label }}
            </a-button>
          </template>
          <!--  这个不要显示   -->
          <template v-else-if="item.type === 'selectAll'"> </template>

          <template v-else>
            <a-button
              :key="item.type"
              :type="item.btnType"
              @click="handleClick(item)"
              v-if="rowSelection.selectedRowKeys.length > 0 || checkAll === true"
            >
              <template #icon>
                <component :is="icons[item['icon']]" />
              </template>
              {{ item.label }}
            </a-button>
          </template>
        </template>
      </a-space>
      <contact-search-form
        ref="searchRef"
        :items="items"
        :formWidth="formWidth"
        :default-value="search"
        @fnSearch="handleFnSearch"
      />

      <!--      <template v-for="item in btnOperation">-->
      <!--        <template v-if="item.type === 'delete' && isDrag">-->
      <!--          <a-button :key="item.type" @click="handleClickDelete(item)" class="delClass colorRed">-->
      <!--            <template #icon>-->
      <!--              <component :is="icons[item.icon || 'DeleteOutlined']" />-->
      <!--            </template>-->
      <!--&lt;!&ndash;            {{ item.label || '批量删除' }}&ndash;&gt;-->
      <!--          </a-button>-->
      <!--        </template>-->
      <!--      </template>-->
    </div>
  </div>
  <div class="group-list">
    <template v-for="(item, index) in dataGroupSource" :key="index">
      <div class="group-item" @click="openGroupList(item.id)">
        <img
          class="group-icon"
          src="@/assets/images/group/folder-icon.png"
          alt=""
        />
        <span>{{ item.groupName }}</span>
        <img class="arrow" src="@/assets/images/group/arrow.png" alt="" />
      </div>
    </template>
  </div>
  <!--  :pagination="false" -->
  <a-table
    class="iss-grid"
    rowKey="id"
    size="middle"
    v-bind="$attrs"
    :row-selection="{
      rowSelection,
       onSelect: onSelect,
       onSelectAll: onSelectAll,
       selectedRowKeys: selectedRowKeys,
       onChange: onSelectChange,

    }"
    :data-source="dataSource"
    :loading="loading"
    :pagination="pagingConfig"
  >
    <template v-for="(index, name) in $slots" v-slot:[name]="slotProps">
      <slot :name="name" v-bind="slotProps" />
    </template>
  </a-table>
</template>

<script>
import { createVNode, toRaw } from 'vue';
import {
  message,
  Modal,
  Space,
  Table,
  Upload,
  Row,
  Col,
  Checkbox,
  Dropdown,
  Menu,
} from 'ant-design-vue';
import * as icons from '@ant-design/icons-vue';
import contactSearchForm from '@/components/contactSearchForm';
import issSift from '@/components/sift';
import { session } from '@/utils/storage';
import router from '@/router';

// import screenfull from "screenfull";
// import contactAll from '@/api/contactAll';
// import { downloadExcel } from '@/utils';

export default {
  name: 'Gird',
  components: {
    ASpace: Space,
    ATable: Table,
    AUpload: Upload,
    ARow: Row,
    ACol: Col,
    contactSearchForm,
    ACheckbox: Checkbox,
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    // APopover: Popover,
    issSift,
  },
  props: {
    code: String,
    formWidth: String,
    url: String,
    tableTitle: String,
    urlParams: Object,
    cricle: Boolean,
    allowSelection: [Boolean, Object],
    btnOperation: Array,
    items: Array,
    pagination: {
      type: [Object, Boolean],
      default: () => ({}),
    },
  },
  data() {
    return {
      icons,
      loading: false,
      visible: false,
      isShow: false,
      isShowSift: false,
      showExtend: false,
      dataSource: [],
      dataSourceId: [],
      dataGroupSource: [],
      checkAll: false,
      search: {},
      selectedRowKeys: [],
      selectedRows: [],
      cancelRowsId: [],
      list: [
        //  <SwapOutlined />
        { value: 'last_interaction_time', label: '最后互动', id: '1' },
        { value: 'name', label: '名字', id: '2' },
        { value: 'create_time', label: '最新添加', id: '3' },
        { value: 'frequency', label: '联系频次', id: '4' },
        // { value: 'frequency', label: '无', id: '4' },
      ],

      cricleList: [
        { value: '1', label: '名字', id: '2' },
        { value: '2', label: '最新添加', id: '3' },
        { value: '0', label: '无', id: '3' },
      ],

      fieldList: [
        { value: 'company', label: '公司', id: '1' },
        { value: 'position', label: '职位', id: '2' },
        { value: 'describe', label: '备注', id: '3' },
        { value: 'frequency', label: '频次', id: '4' },
        { value: 'last_interaction_time', label: '最后互动', id: '5' },
        { value: 'socialize_url', label: '社交媒体链接', id: '6' },
      ],
      // todo 要传给后端筛选的字段
      toApiList: [],

      pagingConfig:
        this.pagination === false ? false : this.setPagination(this.pagination),
    };
  },
  // 全局创建一个点击事件:bodyCloseMenus
  mounted() {
    const that = this;
    document.addEventListener('click', this.bodyCloseMenus);
    this.$bus.on('getTempTwo', obj => {
      let { temp } = obj;
      if (temp === 1 || temp === 2 || temp === 3 || temp === 4 || temp === 5) {
        const arr = session.get('dataRecord');
        !that.selectedRowKeys.includes(arr[0].id) &&
          that.selectedRowKeys.push(arr[0].id);
      } else if (temp === null) {
        that.selectedRowKeys = [];
      } else {
        that.selectedRowKeys = [];
      }
    });
  },
  // 在页面注销前，将点击事件给移除
  beforeUnmount() {
    document.removeEventListener('click', this.bodyCloseMenus);
  },
  created() {
    if (this.url) {
      const { current, pageSize } = this.pagingConfig;
      pageSize ? this.queryListByPaging(current, pageSize) : this.queryList();
    }
  },
  computed: {
    rowSelection() {
      return this.allowSelection
        ? {
            fixed: 'left',
            selectedRowKeys: this.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
              this.selectedRowKeys = selectedRowKeys;
              this.selectedRows = selectedRows;

              //   关闭筛选和字段弹窗
              if (this.selectedRowKeys.length > 0) {
                this.isShowSift = false;
                this.showExtend = false;
                this.isShow = false;
              }
            },
            ...this.allowSelection,
          }
        : null;
    },
  },
  watch: {
    search: {
      deep: true,
      handler(val) {
        const { pageSize } = this.pagingConfig;
        pageSize ? this.queryListByPaging(1, pageSize) : this.queryList();
        this.$bus.emit('CONN-SEARCH', val);
      },
    },
    urlParams: {
      handler(val) {
        if (JSON.stringify(toRaw(this.queryParams)) !== JSON.stringify(val)) {
          this.queryParams = val;
          const { pageSize } = this.pagingConfig;
          pageSize ? this.queryListByPaging(1, pageSize) : this.queryList();
        }
      },
    },

    checkAll: {
      // 监听跨页全选框
      // 勾选跨页时，第一页勾选，切换第二页会默认取消了勾选
      handler(val) {
        console.log('跨页全选', val, this.dataSource, this.cancelRowsId)

        if (val) {
          this.checkAll = true

          for (let i = 0; i < this.dataSource.length; i++) {
            // 需要排除调取消勾选的，this.cancelRowsId 包含 this.dataSource[i].id
            if (!this.cancelRowsId.includes(this.dataSource[i].id)) {
              // debugger
              this.selectedRowKeys.push(this.dataSource[i].id);
            }
          }

        } else {
          // this.checkAll = false
          this.selectedRowKeys = [];
          this.cancelRowsId = [];

        }

      }

    },

  },
  methods: {
    //

    onSelect(selectedRowKeys, selectedRows) {
      console.log('onSelect', selectedRowKeys, selectedRows)
      // 	用户手动选择/取消选择某列的回调
      //  selectedRows 是false 的话，需要在 this.selectedRowKeys 里面把这个id 清除掉

      // 把取消勾选的id存起来
      if (!this.cancelRowsId.includes(selectedRowKeys.id)) {
        this.cancelRowsId.push(selectedRowKeys.id)
      }
      // console.log('rowSelection已经选中的数据', this.selectedRowKeys)
      // 渲染出来的数据中排除已经取消的勾选的id
      if (!selectedRows && this.selectedRowKeys.includes(selectedRowKeys.id)) {
        let newSelectedRowKeys = this.selectedRowKeys.filter((item)=> item !== selectedRowKeys.id)
        this.selectedRowKeys = newSelectedRowKeys
      }

      let result =  this.cancelRowsId.filter(key => !this.selectedRowKeys.includes(key));
      this.cancelRowsId = result
      // console.log('最终定的数据', this.selectedRowKeys, this.cancelRowsId)

    },
    onSelectAll(selected, selectedRows, ) {
      // console.log('onSelectAll6666', selected, selectedRows, changeRows)
      //   点击当前页的取消按钮，需要在 rowSelection 里面批量把这id 清除掉
      if (selected) {

        selectedRows.map((item)=>{
          if (!this.selectedRowKeys.includes(item.id)) {
            this.selectedRowKeys.push(item.id)
          }
        })

      } else {
        //  取消勾选分当页取消勾选和跨页取消勾选

        // 取消单页全选，也判断是否选择了跨页全选， 如果选择了跨页全选，那么需要拿到当前页的id,排除掉，取消勾选
        //

        if (this.checkAll) {

          // 把当前页数据id添加到取消勾选的数组
          this.dataSource.map((item)=>{
            if (!this.cancelRowsId.includes(item.id)) {
              this.cancelRowsId.push(item.id)
            }
          })
          if (selectedRows.length > 0) {
            // 把批量取消勾选的id存起来
            selectedRows.map((item)=> {
              if (!this.cancelRowsId.includes(item.id)) {

                // console.log('item.id 当前页', item.id)
                // 这个赋值有问题，把之前勾选上的全部取消勾选，应该只是取消勾选当前页
                // this.cancelRowsId.push(item.id)
                // let result =  this.selectedRowKeys.filter(key => ! this.cancelRowsId.includes(key));
                // console.log('result', result);
                // this.selectedRowKeys =  result


              } else {
                let result =  this.selectedRowKeys.filter(key => !this.cancelRowsId.includes(key));
                // console.log('result 22', result , this.cancelRowsId);
                this.selectedRowKeys =  result
              }

            })
          } else {
            //  处理跨页全选，直接把第一页取消勾选的情况
            let result =  this.selectedRowKeys.filter(key => ! this.cancelRowsId.includes(key));
            // console.log('result 22', result , this.cancelRowsId);
            this.selectedRowKeys =  result
          }

        } else {
          this.selectedRowKeys = []
        }

      }

    },

    //  selectedRowKeys, selectedRows
    // 勾选当前页的快捷键
    onSelectChange(selectedRowKeys, selectedRows) {
      // console.log('onSelectChange当前页', selectedRowKeys, selectedRows)

      selectedRowKeys.map((item)=> {
        // console.log('item当前页 快捷键', item)
        if (!this.selectedRowKeys.includes(item)) {
          this.selectedRowKeys.push(item)
        }
      })
      // 取消的id中包含排除选中的id
      // console.log('this.selectedRowKeys', this.selectedRowKeys)
      let result =  this.cancelRowsId.filter(key => !this.selectedRowKeys.includes(key));
      this.cancelRowsId = result

    },

    handleFnSearch(val) {
      this.search = val;
    },
    setPagination(data) {
      const { onChange, onShowSizeChange } = data;
      return Object.assign(
        {
          showLessItems: true,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSize: 10,
          current: 1,
          size: 'default',
          pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
          showTotal: total => `共 ${total} 条数据`,
        },
        data,
        {
          onChange: (current, pageSize) =>
            this.queryListByPaging(current, pageSize).then(() =>
              onChange?.(current, pageSize)
            ),
          onShowSizeChange: (current, pageSize) =>
            this.queryListByPaging(current, pageSize).then(() =>
              onShowSizeChange?.(current, pageSize)
            ),
        }
      );
    },
    // 分页请求
    queryListByPaging(current, size) {
      this.loading = true;
      return this.$http
        .post(this.url, {
          current,
          size,
          ...this.urlParams,
          ...this.search,
          code: this.code,
        })
        .then(res => {
          console.log('res66', res);
          this.dataSource = res.contact;
          this.dataGroupSource = res.package;

          session.set('dataRecord', this.dataSource);
          // 分页刷新请求时 至空 selectedRowKeys 全选按钮
          this.dataSourceId = [];

          // 开启勾选分页切换下一页时 保留上一页勾选状态
          // this.selectedRowKeys = [];
          // 每次分页请求时，如果checkAll 是true ， 那么需要把当前页数据勾选上，
          // 需要排除掉已经取消勾选的

          if (this.checkAll) {
            this.checkAll = true
            //
            for (let i = 0; i < this.dataSource.length; i++) {
              // 需要排除调取消勾选的，this.cancelRowsId 包含 this.dataSource[i].id
              if (!this.cancelRowsId.includes(this.dataSource[i].id)) {
                this.selectedRowKeys.push(this.dataSource[i].id);
              }
            }

          } else {
            this.checkAll = false;
          }


          for (let i = 0; i < res.contact?.length; i++) {
            this.dataSourceId.push(res.contact[i].id);
          }
          this.$emit('dataChange', {
            dataSource: this.dataSource,
            type: 'currentData',
          });

          // this.dataSource = records;
          // session.set('dataRecord', records);
          // // 分页刷新请求时 至空 selectedRowKeys 全选按钮
          // this.dataSourceId = [];
          // this.selectedRowKeys = [];
          // this.checkAll = false;
          // // todo

          // for (let i = 0; i < records.length; i++) {
          //   this.dataSourceId.push(records[i].id);
          // }
          // this.$emit('dataChange', {
          //   dataSource: this.dataSource,
          //   type: 'currentData',
          // });
          this.pagingConfig = Object.assign({}, this.pagingConfig, {
            current,
            pageSize: size,
            total: parseInt(res.total),
          });
        })
        .finally(() => (this.loading = false));
    },
    queryList() {
      this.loading = true;
      return this.$http
        .get(this.url, {
          params: { ...this.urlParams, ...this.search },
          code: this.code,
        })
        .then(res => {
          this.dataSource = res.contact;
          this.dataGroupSource = res.package;
          session.set('dataRecord', this.dataSource);
          this.$emit('dataChange', this.dataSource.length);
        })
        .finally(() => (this.loading = false));
    },
    // refreshGrid(isCurrent) {
    //   const { current, pageSize } = this.pagingConfig;
    //   pageSize
    //     ? this.queryListByPaging(isCurrent ? current : 1, pageSize)
    //     : this.queryList();
    //   this.selectedRowKeys = [];
    // },

    refreshGrid(delLength,) {
      const { current, pageSize, total,  } = this.pagingConfig;
      // 列表删除掉的个数 delLength
      let newTotal = total - delLength
      // newTotalToStr 字符串后面的数字是0， 表示这页删除完，回到第一页去
      let newTotalToStr = newTotal.toString().charAt(newTotal.toString().length - 1)

      pageSize
        ? this.queryListByPaging(newTotalToStr === '0' ? '1' : current, pageSize)
        : this.queryList();
      this.selectedRowKeys = [];

      // pageSize
      //   ? this.queryListByPaging(isCurrent ? current : 1, pageSize)
      //   : this.queryList();
      // this.selectedRowKeys = [];

      // 保存完毕，需要对列表刷新处理， 去掉全选的勾选状态
      this.checkAll = false

    },

    // 清空筛选的值
    clearSearch() {
      this.search = {}
      // 清空搜索框的值
      this.$refs.searchRef.resetFields();
    },

    getDataSource() {
      let dataSource = this.dataSource;
      return dataSource;
    },
    // grid勾选数据的数组
    getSelectedRowsKeys() {
      // 存储在store看看
      return this.selectedRowKeys;
    },

    // grid勾选数据的数组
    getSelectedRowsKeysAll() {
      //  checkAll 是true, cancelRowsId 和 checkAll
      // checkAll 是false, selectedRowKeys 和 checkAll
      let parmas = {}

      if (this.checkAll) {
        parmas = {
          selectAll: this.checkAll,
          id: this.cancelRowsId,
        }
      } else {
        parmas = {
          selectAll: this.checkAll,
          id: this.selectedRowKeys,
        }
      }
      return parmas;
    },
    isTotal() {
      const { total  } = this.pagingConfig;
      return total
    },

    handleClick(item) {
      // let permission = item.permission;
      // typeof permission === 'string' && (permission = [permission]);
      // this.$has(...permission).then(() => {

      const selectedRowKeys = toRaw(this.selectedRowKeys);
      if (item.type === 'delete') {
        const length = selectedRowKeys.length;
        if (length) {
          Modal.confirm({
            title: `确认删除选中的 ${length} 条数据吗？`,
            icon: createVNode(icons['ExclamationCircleOutlined']),
            okType: '',
            okText: '确认',
            onOk: () => item.fnClick(selectedRowKeys),
          });
        } else {
          message.info('请先选择数据');
        }
      } else if (item.type === 'field') {
        this.isShow = !this.isShow;
        this.isShowSift = false;
        this.showExtend = false;
      } else if (item.type === 'sift') {
        this.isShowSift = !this.isShowSift;
        this.isShow = false;
        this.showExtend = false;
      } else if (item.type === 'extend') {
        this.isShowSift = false;
        this.isShow = false;
        this.showExtend = !this.showExtend;
      } else {
        item.fnClick(selectedRowKeys);
      }

      // });
    },
    handleClickFile(item) {
      console.log(item);
      this.visible = true;
    },
    handleClickOk(item) {
      console.log(item);
      this.visible = false;
    },
    handleChangeByCheckAll() {
      //   全选把当前页数据放入selectedRowKeys，并勾选chenkbox
      // todo 增加全选所有数据

      if (this.checkAll) {
        this.selectedRowKeys = this.dataSourceId;
        //   关闭筛选和字段弹窗
        if (this.selectedRowKeys.length > 0) {
          this.isShowSift = false;
          this.showExtend = false;
          this.isShow = false;
        }
      } else {
        this.selectedRowKeys = [];
      }
    },

    handleClickByMenu({ key }) {
      console.log('key', key);
      this.$emit('fnGrid', { value: key, type: 'sort' });
    },
    handleFieldItem(i) {
      // todo 数据如何传到接口，数据变化时刷新grid setStatus
      this.fieldList.map(item => {
        if (item.id === i.id) {
          item.setStatus = !item?.setStatus;
          if (item.setStatus) {
            this.toApiList = this.toApiList.filter(
              toApiItem => toApiItem !== item.value
            );
          }
        }
      });

      this.fieldList.map(item => {
        if (!item.setStatus) {
          // toApiList数组需要做去重
          if (!this.toApiList.includes(item.value)) {
            this.toApiList.push(item.value);
          }
        }
      });

      // 刷新grid
      this.$emit('fnGrid', { value: this.toApiList, type: 'field' });
    },
    handleSiftPage(data) {
      // todo 拿到传值后刷新页面
      // console.log('grid组件筛选', data);
      // 关闭筛选条件窗口
      // this.isShowSift = value
      // 刷新grid
      this.$emit('fnGrid', { value: data, type: 'sift' });
    },
    handleDelete() {
      let parmas = {}
      if (this.checkAll) {
        parmas = {
          userId: this.$store.state.account.user.id,
          selectAll: this.checkAll,
          contactId: this.cancelRowsId,
        }
      } else {
        parmas = {
          userId: this.$store.state.account.user.id,
          selectAll: this.checkAll,
          contactId: this.selectedRowKeys,
        }
      }

      // let params = {
      //   userId: this.$store.state.account.user.id,
      //   contactId: this.selectedRowKeys,
      // };

      this.$emit('fnGrid', { value: parmas, type: 'extend' });
      // this.checkAll = false;
    },

    // 点击空白处收起
    bodyCloseMenus() {
      if (this.showExtend === true) {
        this.showExtend = false;
      }
      if (this.isShowSift === true) {
        this.isShowSift = false;
      }
      if (this.isShow === true) {
        this.isShow = false;
      }
    },

    handleExport() {
      // clueApi
      //     .exportLeadPoolDetail('', ids)
      //     .then(({ name, data }) => downloadExcel(name, data));
    },

    openGroupList(id) {
      router.push('/group/groupItem?id=' + id);
    },
  },
};
</script>

<style lang="less" scoped>
.flex-l {
  font-size: 16px;
  width: auto;
  padding-right: 20px;
  padding-left: 13px;
}
.flex-r {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.iss-extra {
  height: 32px;
  color: #c9cdd4;
  font-size: 14px;
  span {
    position: absolute;
    top: 2px;
  }
}
.iss-grid {
  border-radius: 8px;
  //overflow-y: auto;
  //box-shadow: 0px 3px 10px 0px @primary-3;
  :deep(.ant-table-header) {
    border-radius: 8px 8px 0 0;
    // 解决表头右侧外边距和底纹问题
    //margin-right: -10px;
    background: #fff5eb;
  }
  :deep(.ant-table.ant-table-middle) {
    .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
    .ant-table-content
      > .ant-table-scroll
      > .ant-table-body
      > table
      > .ant-table-tbody
      > tr
      > td,
    .ant-table-content
      > .ant-table-fixed-left
      > .ant-table-body-outer
      > .ant-table-body-inner
      > table
      > .ant-table-tbody
      > tr
      > td,
    .ant-table-content
      > .ant-table-fixed-right
      > .ant-table-body-outer
      > .ant-table-body-inner
      > table
      > .ant-table-tbody
      > tr
      > td {
       padding: 7px 16px;
      //padding: 7px 0;
      height: 48px;
    }
    .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
    .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
    .ant-table-content
      > .ant-table-scroll
      > .ant-table-header
      > table
      > .ant-table-thead
      > tr
      > th,
    .ant-table-content
      > .ant-table-scroll
      > .ant-table-body
      > table
      > .ant-table-thead
      > tr
      > th,
    .ant-table-content
      > .ant-table-fixed-left
      > .ant-table-header
      > table
      > .ant-table-thead
      > tr
      > th,
    .ant-table-content
      > .ant-table-fixed-right
      > .ant-table-header
      > table
      > .ant-table-thead
      > tr
      > th,
    .ant-table-content
      > .ant-table-fixed-left
      > .ant-table-body-outer
      > .ant-table-body-inner
      > table
      > .ant-table-thead
      > tr
      > th,
    .ant-table-content
      > .ant-table-fixed-right
      > .ant-table-body-outer
      > .ant-table-body-inner
      > table
      > .ant-table-thead
      > tr
      > th,
    .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
    .ant-table-content
      > .ant-table-scroll
      > .ant-table-header
      > table
      > .ant-table-tbody
      > tr
      > td,
    .ant-table-fixed-left
      > .ant-table-header
      > table
      > .ant-table-tbody
      > tr
      > td,
    .ant-table-fixed-right
      > .ant-table-header
      > table
      > .ant-table-tbody
      > tr
      > td {
      padding: 12px 16px;
      height: 48px;
      // background: rgba(255, 123, 0, 0.04);
      // 解决表格左右滑动表头两侧显示透明问题
      background: #fff5eb;
    }
  }
  :deep(.ant-btn-icon-only) {
    min-width: 30px;
    width: 30px;
    height: 30px;
  }
  :deep(.ant-table-pagination.ant-pagination) {
    margin: 20px 20px 0px 0;
    font-size: 12px;
    .ant-pagination-options-quick-jumper input,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: #fff;
    }
    a {
      font-size: 14px;
    }
  }
}
.ant-btn {
  color: #6b7592;
}
.flex-top /deep/ .ant-btn:active {
  color: #6b7592;
  background: #ffffff;
  border-color: #eeeff5;
}
//.ant-btn:hover, .ant-btn:focus {
//  color: #6B7592;
//  background: #ffffff;
//  border-color: #EEEFF5;
//}

.group-img {
  margin-right: 4px;
  width: 16px;
}
.edit-img {
  margin-right: 4px;
  width: 16px;
}
.contact-img {
  margin-right: 4px;
  width: 16px;
}
.sift-img {
  margin-right: 2px;
  width: 16px;
}
//.delete-img {
//  margin-right: 2px;
//  width: 13px;
//}
.delete-img,
.gridHeader-img {
  margin-top: 2px;
  margin-right: 4px;
  width: 16px;
}
.selectAllNone {
  display: none;
}
.checkClass {
  margin-right: 8px;
}
.fieldEle {
  position: absolute;
  //border-radius: 8px;
  opacity: 1;
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
  z-index: 2;
  padding: 12px 16px;
  width: 316px;
  .fieldValue {
    position: relative;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: #ffffff;
    //padding: 4px 16px;
    .fieldTitle {
      color: #6b7592;
      font-size: 12px;
    }
    .fieldItem {
      background: #fffdfb;
      border: 1px solid rgba(252, 120, 25, 0.2);
      padding: 2px 12px;
      text-align: center;
      border-radius: 4px;
      margin: 8px;
      cursor: pointer;
      background: #fffdfb;
      color: #ff7b00;
    }
    .activeFieldItem {
      color: #999999;
      background: rgba(153, 153, 153, 0.08);
      border: 1px solid rgba(153, 153, 153, 0.2);
    }
  }
}

.extend {
  position: absolute;
  //border-radius: 8px;
  opacity: 1;
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
  z-index: 2;
  padding: 12px 16px;
  width: 126px;
  color: #6b7592;
  .fieldValue {
    position: relative;
    top: 0;
    right: 0;
    background: #ffffff;
    cursor: pointer;
    .exportClass {
      margin-bottom: 12px;
    }
  }
}

// 筛选条件
.siftCalss {
  position: absolute;
  //border-radius: 8px;
  opacity: 1;
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
  z-index: 2;
  padding: 12px 16px;
  width: 494px;
  right: 110px;
  .fieldValue {
    position: relative;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: #ffffff;
    //padding: 4px 16px;
    .fieldTitle {
      color: #6b7592;
      font-size: 12px;
    }
    .fieldItem {
      background: #fffdfb;
      border: 1px solid rgba(252, 120, 25, 0.2);
      padding: 2px 12px;
      text-align: center;
      border-radius: 4px;
      margin: 8px;
      cursor: pointer;
      background: #fffdfb;
      color: #ff7b00;
    }
    .activeFieldItem {
      color: #999999;
      background: rgba(153, 153, 153, 0.08);
      border: 1px solid rgba(153, 153, 153, 0.2);
    }
  }
}
.delClass {
  border: none;
  color: #fa5151;
}
.delClass /deep/ .ant-btn-icon-only {
  width: 0px;
  height: 0px;
  padding: 0px 0;
}
.iss-form {
  padding: 0 12px;
}
.buttonAll {
  border: none;
  padding-left: 10px;
}
.flex-r /deep/ .asbbbbb {
  display: none;
}
.deleteClass {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.butStyle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.export-img {
  padding: 7px 0px;
}

//.iss-grid /deep/
//.ant-table.ant-table-middle
//.ant-table-content > .ant-table-scroll >
//.ant-table-body > table > .ant-table-tbody >
//tr > td, .iss-grid[data-v-034532b4]
//.ant-table.ant-table-middle .ant-table-content >
//.ant-table-fixed-left > .ant-table-body-outer >
//.ant-table-body-inner > table > .ant-table-tbody > tr > td,
//.iss-grid[data-v-034532b4] .ant-table.ant-table-middle .ant-table-content >
//.ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td {
//  padding: 7px 0px;
//}

.group-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .group-item {
    width: 100%;
    padding: 10px 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #eeeeee;
    cursor: pointer;

    &:hover {
      background: rgba(#ff7b00, 0.08);
    }

    .group-icon {
      width: 16px;
      height: 16px;
      object-fit: cover;
    }

    span {
      margin-left: 10px;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }

    .arrow {
      width: 16px;
      height: 16px;
      margin-left: auto;
      object-fit: cover;
    }
  }
}
</style>
